import { render, staticRenderFns } from "./search-product.vue?vue&type=template&id=3c29b7bc&scoped=true"
import script from "./search-product.vue?vue&type=script&lang=ts"
export * from "./search-product.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c29b7bc",
  null
  
)

export default component.exports